:root {
  --primary-color: #B08D44;
  --primary-dark: #8F7A4A;
  --primary-light: #C9AD6E;
  --primary-lighter: #D5BE89;
  --primary-lightest: #F2EBE0;
  --text-primary: #2d2d2d;
  --text-secondary: #4a4a4a;
  --background-light: #f9f7f4;
  --background-dark: #2d2d2d;
  --border-color: #e2e2e2;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --transition-fast: 150ms;
  --transition-normal: 300ms;
  --transition-slow: 500ms;
}

body {
  background-color: var(--background-light);
  color: var(--text-primary);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

/* Animation classes */
.fade-in {
  animation: fadeIn var(--transition-normal) ease-in-out;
}

.slide-in-left {
  animation: slideInLeft var(--transition-normal) ease-in-out;
}

.slide-in-right {
  animation: slideInRight var(--transition-normal) ease-in-out;
}

.slide-in-up {
  animation: slideInUp var(--transition-normal) ease-in-out;
}

.pulse {
  animation: pulse 2s infinite;
}

/* Hover effects */
.hover-scale {
  transition: transform var(--transition-fast) ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.05);
}

.hover-shadow {
  transition: box-shadow var(--transition-fast) ease-in-out;
}

.hover-shadow:hover {
  box-shadow: var(--shadow-lg);
}

/* Animation keyframes */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInLeft {
  from { transform: translateX(-20px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideInRight {
  from { transform: translateX(20px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideInUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}

.btn-primary:hover {
  background-color: var(--primary-dark) !important;
  border-color: var(--primary-dark) !important;
}

.btn-outline-primary {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-outline-primary:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--text-secondary);
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.nav-link.active {
  color: var(--primary-color) !important;
  border-bottom: 2px solid var(--primary-color);
}

.nav-link:hover {
  color: var(--primary-dark) !important;
}

.table th {
  background-color: var(--background-light);
  color: var(--text-secondary);
  border-bottom: 2px solid var(--primary-color) !important;
}

.table td {
  border-color: var(--border-color);
}

.card {
  border: none;
  box-shadow: var(--shadow);
  border-radius: 8px;
  transition: all var(--transition-normal) ease-in-out;
}

.card:hover {
  box-shadow: var(--shadow-md);
}

.card-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--border-color);
  font-weight: 600;
}

.card-body {
  padding: 1.5rem;
}

.card-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid var(--border-color);
}

.form-control {
  transition: all var(--transition-fast) ease-in-out;
  border-radius: 0.375rem;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(143, 122, 74, 0.25);
}

/* Custom button styles */
.btn {
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  transition: all var(--transition-fast) ease-in-out;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
}

.page-link {
  color: var(--primary-color);
}

.page-item.active .page-link {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.navbar {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.sidebar {
  background-color: white;
  border-right: 1px solid var(--border-color);
}

.search-box {
  border-radius: 20px;
  border: 1px solid var(--border-color);
  padding: 8px 16px;
}

.search-box:focus {
  box-shadow: none;
  border-color: var(--primary-color);
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-primary-dark {
  background-color: var(--primary-dark) !important;
}

.bg-primary-light {
  background-color: var(--primary-light) !important;
}

.bg-primary-lighter {
  background-color: var(--primary-lighter) !important;
}

.bg-primary-lightest {
  background-color: var(--primary-lightest) !important;
}

.bg-light {
  background-color: var(--background-light);
}

.bg-dark {
  background-color: var(--background-dark);
}

.hover\:bg-primary-dark:hover {
  background-color: var(--primary-dark) !important;
}

.hover\:text-primary:hover {
  color: var(--primary-color) !important;
}

.focus\:ring-primary:focus {
  --tw-ring-color: var(--primary-color) !important;
  --tw-ring-opacity: 0.5;
}

.focus\:border-primary:focus {
  border-color: var(--primary-color) !important;
}

.btn {
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  border-color: var(--primary-color);
}
